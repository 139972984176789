import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client/errors";

import { Box, TextField, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { InstantPayoutIcon } from "design-system/icons";
import { black, white } from "design-system/colors";
import { NumberFormatDollar } from "design-system/components/inputs/NumberFormat";
import {
  DollarPercentVariant,
  normalizeValueToBeDisplayedAsDollar,
} from "components/pricing/utils";
import { normalizeOnInputDollarValue } from "components/pricing/utils/displayUtils";
import {
  CREATE_INSTANT_PAYOUT_MUTATION,
  LOAD_AMOUNT_AWAITING_PAYOUT,
  LOAD_STRIPE_PAYOUTS_QUERY,
} from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { getErrorCode, getErrorMessage } from "moovsErrors/getErrorMessage";

type CreateInstantPayoutDialogProps = {
  open: boolean;
  onClose: () => void;
  instantAvailableBalance: number;
};

const { Dollar } = DollarPercentVariant;
const payoutFeePercentage = 0.0175; // 1.75%

function CreateInstantPayoutDialog(props: CreateInstantPayoutDialogProps) {
  const { open, onClose, instantAvailableBalance } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [payoutAmount, setPayoutAmount] = useState<number>(null);
  const [createInstantPayoutLoading, setCreateInstantPayoutLoading] =
    useState(false);
  const [error, setError] = useState<
    "insufficient" | "max-range" | "min-range"
  >(null);
  const instantPayoutFee = useMemo(
    () =>
      normalizeValueToBeDisplayedAsDollar({
        value: payoutAmount * payoutFeePercentage,
        variant: Dollar,
      }),
    [payoutAmount]
  );

  // effects
  useEffect(() => {
    if (open) {
      setPayoutAmount(instantAvailableBalance);
    }
  }, [open, instantAvailableBalance]);

  // mutation
  const [createInstantPayout] = useMutation(CREATE_INSTANT_PAYOUT_MUTATION, {
    onCompleted(data) {
      setCreateInstantPayoutLoading(false);
      const { payout } = data.createInstantPayout;

      if (payout.stripePayoutId) {
        snackbar.success("Successfully started instant payout!");
        track("instantPayout_submitted");
      }

      handleOnClose();
    },
    onError(error: ApolloError) {
      setCreateInstantPayoutLoading(false);
      const errorCode = getErrorCode(error);
      if (errorCode === "MOOVS_RISK_LEVEL_TOO_HIGH") {
        const errorMessage = "Your account reputation is too low to use instant payouts. Please reach out to support@moovsapp.com for information on how to increase your account reputation.";
        snackbar.error(errorMessage);
      } else {
        snackbar.error(getErrorMessage(error));
      }
    },
    refetchQueries: [LOAD_STRIPE_PAYOUTS_QUERY, LOAD_AMOUNT_AWAITING_PAYOUT],
    awaitRefetchQueries: true,
  });

  // helper
  const validateInput = () => {
    if (!payoutAmount || payoutAmount < 0.5) {
      setError("min-range");
    } else if (payoutAmount > 9999) {
      setError("max-range");
    } else if (payoutAmount > instantAvailableBalance) {
      setError("insufficient");
    } else {
      setError(null);
    }
  };

  const helperTextMap = (error) => {
    switch (error) {
      case "insufficient":
        return (
          <Typography variant="body2">
            Insufficient available funds. Available balance:{" "}
            <strong>
              $
              {normalizeValueToBeDisplayedAsDollar({
                value: instantAvailableBalance,
                variant: Dollar,
              })}
            </strong>
          </Typography>
        );
      case "min-range":
        return (
          <Typography variant="body2">
            The entered amount is below the minimum limit of $0.50. Please enter
            an amount within the allowed range.
          </Typography>
        );
      case "max-range":
        return (
          <Typography variant="body2">
            The entered amount exceeds the maximum limit of $9,999. Please enter
            an amount within the allowed range.
          </Typography>
        );
      default:
        return (
          <Typography variant="body2" color={black}>
            Available balance:{" "}
            <strong>
              $
              {normalizeValueToBeDisplayedAsDollar({
                value: instantAvailableBalance,
                variant: Dollar,
              })}
            </strong>
          </Typography>
        );
    }
  };

  // event handlers
  const handleOnClose = () => {
    setError(null);
    setPayoutAmount(null);
    onClose();
  };

  const handlePayoutAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) setError(null);

    const normalizedPayoutAmount = normalizeOnInputDollarValue(e);

    if (isNaN(normalizedPayoutAmount)) return;

    if (e.target.value === "" || e.target.value === "$ ") {
      setPayoutAmount(null);
    } else {
      setPayoutAmount(normalizedPayoutAmount);
    }
  };

  const handleStartPayout = () => {
    if (error) return;

    setCreateInstantPayoutLoading(true);

    createInstantPayout({
      variables: {
        input: {
          payoutAmount,
        },
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleOnClose}
      onAccept={handleStartPayout}
      dialogTitle="Start Instant Payout"
      acceptButtonText="Start Payout"
      acceptStartIcon={
        <InstantPayoutIcon
          color={createInstantPayoutLoading ? "rgba(0, 0, 0, 0.26)" : white}
        />
      }
      closeButtonText="Cancel"
      size="sm"
      hideLoadingIndicator={!createInstantPayoutLoading}
      acceptDisabled={createInstantPayoutLoading}
    >
      <Box py={3}>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          label="Payout Amount"
          onInput={handlePayoutAmountInput}
          onBlur={validateInput}
          value={normalizeValueToBeDisplayedAsDollar({
            value: payoutAmount,
            variant: Dollar,
          })}
          InputProps={{
            inputComponent: NumberFormatDollar as any,
          }}
          error={!!error}
          helperText={helperTextMap(error)}
        />

        <Box display="flex" flexDirection="column" p={2}>
          {/* fee */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">Instant payout fee:</Typography>
            <Typography variant="subtitle2">${instantPayoutFee}</Typography>
          </Box>
        </Box>
      </Box>
    </MoovsDialog>
  );
}

export default CreateInstantPayoutDialog;
