/**
 * @file graphql/trip
 * queries/mutations for Trip.
 *
 */

import { gql } from "@apollo/client";

export const TRIP_SUMMARY_FIELDS = gql`
  {
    id
    totalDuration
    estimatedDuration
    useTotalDuration
    totalGroupSize
    createdAt
    updatedAt
    cancelledAt
    requestId
    tripNumber
    reminderTextTo
    statusUpdateTextTo
    tripCategory
    roundTripVariant
    paymentStatus
    amountDue
    totalAmount
    closedAt
    temporaryPassenger
    tempPassenger {
      name
      mobilePhone
      phoneCountryCode
      phoneCountryName
      phoneCountryFormat
      phoneCountryDialCode
      mobilePhoneInternational
    }
    isTripInvoiced
    comments {
      id
      bodyText
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
      }
    }
    googleDirectionsResult
    note
    childSeats
    farmedRouteStatus
    otherTripsFarmedRouteStatus
    closeOutDisabledVariant
    reopenDisabled
    returnTrip {
      id
      totalDuration
      estimatedDuration
      useTotalDuration
      totalGroupSize
      createdAt
      updatedAt
      cancelledAt
      requestId
      tripNumber
      tripCategory
      paymentStatus
      roundTripVariant
      amountDue
      totalAmount
      closedAt
      temporaryPassenger
      comments {
        id
        bodyText
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
        }
      }
      googleDirectionsResult
      note
      childSeats
      farmedRouteStatus
      otherTripsFarmedRouteStatus
      closeOutDisabledVariant
      routes {
        canEditPricing
        publicId
        id
        groupSize
        operatorConfirmation
        driverNote
        dispatchStatus
        isFarmedRoute
        farmRelationship
        canEditFarmeePricing
        driverPayoutFlatRate
        driverPayoutHours
        driverPayoutGratuity
        driverPayoutRatePerHour
        driverPayoutGratuity
        automatedDriverPayoutFlatRate
        automatedDriverPayoutHours
        automatedDriverPayoutRatePerHour
        automatedDriverPayoutGratuity
        hasAutomatedDriverPayout
        carryOnLuggage
        checkedLuggage
        oversizeLuggage
        externalConfirmationNumber
        pricing {
          id
          baseRateAmt
          hourlyBaseRate
          hourlyBaseRateHours
          driverGratuityAmt
          driverGratuityPercent
          promoDiscountAmt
          promoDiscountPercent
          taxAmt
          taxPercent
          tollsAmt
          meetGreetAmt
          otherName
          otherAmt
          otherPercent
          other2Name
          other2Amt
          other2Percent
          other3Name
          other3Amt
          other3Percent
          promoCodeId
          promoCodeName
          promoCodeAmt
          promoCodePercent
          forwardFacingSeatQuantity
          forwardFacingSeatAmt
          rearFacingSeatQuantity
          rearFacingSeatAmt
          boosterSeatQuantity
          boosterSeatAmt
          totalAmt
          amountPaid
          paymentStatus
        }
        farmeePricing {
          id
          baseRateAmt
          hourlyBaseRate
          hourlyBaseRateHours
          driverGratuityAmt
          driverGratuityPercent
          promoDiscountAmt
          promoDiscountPercent
          taxAmt
          taxPercent
          tollsAmt
          meetGreetAmt
          otherName
          otherAmt
          otherPercent
          other2Name
          other2Amt
          other2Percent
          other3Name
          other3Amt
          other3Percent
          promoCodeId
          promoCodeName
          promoCodeAmt
          promoCodePercent
          forwardFacingSeatQuantity
          forwardFacingSeatAmt
          rearFacingSeatQuantity
          rearFacingSeatAmt
          boosterSeatQuantity
          boosterSeatAmt
          totalAmt
          amountPaid
          paymentStatus
        }
        farmAffiliateVariant
        requestedVehicleNote
        farmAffiliate {
          ... on ExternalOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
          ... on MoovsNetworkOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
            isMoovsLiteOperator
            paymentMethods {
              id
              isPrimary
              payerId
              cardNote
              card {
                id
                brand
                last4
                expMonth
                expYear
              }
            }
          }
          ... on GriddnetOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
            paymentMethods {
              id
              isPrimary
              payerId
              cardNote
              card {
                id
                brand
                last4
                expMonth
                expYear
              }
            }
          }
          ... on MoovsAiOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
            paymentMethods {
              id
              isPrimary
              payerId
              cardNote
              card {
                id
                brand
                last4
                expMonth
                expYear
              }
            }
          }
        }
        farmStatus
        farmoutNote
        vehicle {
          id
          baseRateAutomation {
            total
            lineItems {
              name
              description
              lineTotal
              source
            }
          }
          enableBaseRateAutomation
          name
          capacity
          description
          photos {
            id
            url
            photoIndex
          }
          vehicleType {
            typeName
            typeSlug
          }
          settings {
            id
            forwardFacingSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
            rearFacingSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
            boosterSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
          }
        }
        driver {
          id
          firstName
          lastName
          mobilePhone
          email
        }
        driverStatus {
          name
          assignedAt
          acceptedAt
          declinedAt
        }
        routeDriver {
          id
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          personalAvailability {
            available
          }
          routeAvailability {
            available
          }
        }
      }
      stops {
        id
        stopIndex
        variant
        location
        locationAlias
        dateTime
        groupSize
        createdAt
        updatedAt
        flightNumber
        note
        originalDateTime
        flightOffset
        airport {
          icaoCode
          iataCode
          airportName
          countryName
          countryIso2
          timezone
          gmt
          coordinates
        }
        airline {
          icaoCode
          iataCode
          airlineName
          callsign
          fleetSize
          countryName
          countryIso2
          dateFounded
        }
        trackedFlight {
          id
          faFlightId
          updatedAt
          airline {
            icaoCode
            airlineName
            iataCode
          }
          actualAirline {
            airlineName
            iataCode
            icaoCode
          }
          flightStatus
          flightNumber
          actualFlightNumber
          origin {
            id
            airport {
              airportName
              iataCode
              icaoCode
              countryName
              countryIso2
              timezone
              gmt
            }
            scheduledGate
            scheduledDateTime
            scheduledTerminal
            actualGate
            actualDateTime
            estimatedDateTime
            actualTerminal
          }
          destination {
            id
            airport {
              airportName
              iataCode
              icaoCode
              countryName
              countryIso2
              timezone
              gmt
            }
            scheduledGate
            scheduledDateTime
            scheduledTerminal
            actualGate
            estimatedDateTime
            actualDateTime
            actualTerminal
          }
        }
      }
      contact {
        id
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
        preferences
        homeAddress
        workAddress
      }
    }
    billings {
      id
      createdAt
      updatedAt
      amount
      note
      action
    }
    comments {
      id
      bodyText
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
      }
    }
    contact {
      id
      firstName
      lastName
      email
      mobilePhone
      mobilePhoneInternational
      preferences
      homeAddress
      workAddress
      company {
        id
        name
      }
    }
    stops {
      id
      stopIndex
      variant
      pickUpVariant
      location
      locationAlias
      dateTime
      groupSize
      createdAt
      updatedAt
      flightNumber
      note
      originalDateTime
      flightOffset
      airport {
        icaoCode
        iataCode
        airportName
        countryName
        countryIso2
        timezone
        gmt
        coordinates
      }
      airline {
        icaoCode
        iataCode
        airlineName
        callsign
        fleetSize
        countryName
        countryIso2
        dateFounded
      }
      trackedFlight {
        id
        faFlightId
        updatedAt
        airline {
          icaoCode
          airlineName
          iataCode
        }
        actualAirline {
          airlineName
          iataCode
          icaoCode
        }
        flightStatus
        flightNumber
        actualFlightNumber
        origin {
          id
          airport {
            airportName
            iataCode
            icaoCode
            countryName
            countryIso2
            timezone
            gmt
          }
          scheduledGate
          scheduledDateTime
          scheduledTerminal
          actualGate
          actualDateTime
          estimatedDateTime
          actualTerminal
        }
        destination {
          id
          airport {
            airportName
            iataCode
            icaoCode
            countryName
            countryIso2
            timezone
            gmt
          }
          scheduledGate
          scheduledDateTime
          scheduledTerminal
          actualGate
          estimatedDateTime
          actualDateTime
          actualTerminal
        }
      }
    }
    routes {
      id
      groupSize
      operatorConfirmation
      driverNote
      dispatchStatus
      isFarmedRoute
      farmRelationship
      canEditPricing
      canEditFarmeePricing
      isFarmedRouteInvoiced
      driverPayoutFlatRate
      driverPayoutHours
      driverPayoutGratuity
      driverPayoutRatePerHour
      driverPayoutGratuity
      automatedDriverPayoutFlatRate
      automatedDriverPayoutHours
      automatedDriverPayoutRatePerHour
      automatedDriverPayoutGratuity
      hasAutomatedDriverPayout
      carryOnLuggage
      checkedLuggage
      oversizeLuggage
      externalConfirmationNumber
      pricing {
        id
        baseRateAmt
        hourlyBaseRate
        hourlyBaseRateHours
        driverGratuityAmt
        driverGratuityPercent
        promoDiscountAmt
        promoDiscountPercent
        taxAmt
        taxPercent
        tollsAmt
        meetGreetAmt
        otherName
        otherAmt
        otherPercent
        other2Name
        other2Amt
        other2Percent
        other3Name
        other3Amt
        other3Percent
        promoCodeId
        promoCodeName
        promoCodeAmt
        promoCodePercent
        forwardFacingSeatQuantity
        forwardFacingSeatAmt
        rearFacingSeatQuantity
        rearFacingSeatAmt
        boosterSeatQuantity
        boosterSeatAmt
        totalAmt
        amountPaid
        paymentStatus
      }
      farmeePricing {
        id
        baseRateAmt
        hourlyBaseRate
        hourlyBaseRateHours
        driverGratuityAmt
        driverGratuityPercent
        promoDiscountAmt
        promoDiscountPercent
        taxAmt
        taxPercent
        tollsAmt
        meetGreetAmt
        otherName
        otherAmt
        otherPercent
        other2Name
        other2Amt
        other2Percent
        other3Name
        other3Amt
        other3Percent
        promoCodeId
        promoCodeName
        promoCodeAmt
        promoCodePercent
        forwardFacingSeatQuantity
        forwardFacingSeatAmt
        rearFacingSeatQuantity
        rearFacingSeatAmt
        boosterSeatQuantity
        boosterSeatAmt
        totalAmt
        amountPaid
        paymentStatus
      }
      farmAffiliateVariant
      requestedVehicleNote
      farmAffiliate {
        ... on ExternalOperator {
          id
          operatorName
          operatorEmail
          operatorPhone
        }
        ... on MoovsNetworkOperator {
          id
          operatorName
          operatorEmail
          operatorPhone
          isMoovsLiteOperator
          paymentMethods {
            id
            isPrimary
            payerId
            cardNote
            card {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
        }
        ... on GriddnetOperator {
          id
          operatorName
          operatorEmail
          operatorPhone
          paymentMethods {
            id
            isPrimary
            payerId
            cardNote
            card {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
        }
        ... on MoovsAiOperator {
          id
          operatorName
          operatorEmail
          operatorPhone
          paymentMethods {
            id
            isPrimary
            payerId
            cardNote
            card {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
        }
      }
      farmStatus
      farmoutNote
      publicId
      driverStatus {
        name
      }
      vehicle {
        id
        baseRateAutomation {
          total
          lineItems {
            name
            description
            lineTotal
            source
          }
        }
        enableBaseRateAutomation
        name
        capacity
        description
        photos {
          id
          url
          photoIndex
        }
        vehicleType {
          typeName
          typeSlug
        }
        settings {
          id
          forwardFacingSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
          rearFacingSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
          boosterSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
        }
      }
      driver {
        id
        firstName
        lastName
        mobilePhone
        email
      }
      routeDriver {
        id
        driver {
          id
          firstName
          lastName
          mobilePhone
          email
        }
        personalAvailability {
          available
        }
        routeAvailability {
          available
        }
      }
    }
  }
`;

export const LOAD_TRIP_QUERY = gql`
  query Trip($id: ID!) {
    node(id: $id) {
      id
      ... on Trip {
        id
        totalDuration
        estimatedDuration
        useTotalDuration
        totalGroupSize
        paymentStatus
        totalAmount
        requestId
        request {
          id
          company {
            id
          }
          bookingContact {
            id
            firstName
            lastName
            linkedPassengers {
              id
              firstName
              lastName
              createdAt
              updatedAt
              email
              mobilePhone
              mobilePhoneInternational
              preferences
              homeAddress
              workAddress
            }
          }
        }
        shuttleRoute {
          id
          name
          company {
            id
            name
          }
        }
        createdAt
        updatedAt
        cancelledAt
        note
        tripNumber
        amountDue
        temporaryPassenger
        tempPassenger {
          name
          mobilePhone
        }
        closedAt
        farmedRouteStatus
        otherTripsFarmedRouteStatus
        isTripInvoiced
        comments {
          id
          bodyText
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
          }
        }
        roundTripVariant
        tripCategory
        returnTrip {
          id
        }
        billings {
          id
          createdAt
          updatedAt
          amount
          note
          action
        }
        subPayments {
          id
          createdAt
          updatedAt
          amount
          payment {
            id
            createdAt
            updatedAt
            stripePayoutDate
            contact {
              id
            }
            amount
            method
            action
            paymentMethod {
              id
              createdAt
              updatedAt
              contactId
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
            }
            note
            paymentStatus
            didPaymentSucceed
          }
          refunds {
            id
            createdAt
            updatedAt
            amount
            note
            method
            paymentStatus
            stripePayoutDate
            paymentMethod {
              id
              createdAt
              updatedAt
              contactId
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
            }
            payment {
              id
              amount
            }
          }
        }
        contact {
          id
          firstName
          lastName
          mobilePhone
          mobilePhoneInternational
          email
          preferences
          homeAddress
          workAddress
        }
        stops {
          id
          createdAt
          updatedAt
          stopIndex
          variant
          dateTime
          location
          locationAlias
          groupSize
          note
          operatorNote
          driverNote
          groupSize
          logisticStreetViewUrl
          flightNumber
          trackedFlight {
            id
            faFlightId
            updatedAt
            airline {
              icaoCode
              airlineName
              iataCode
            }
            actualAirline {
              airlineName
              iataCode
              icaoCode
            }
            flightStatus
            flightNumber
            actualFlightNumber
            origin {
              id
              airport {
                airportName
                iataCode
                icaoCode
                countryName
                countryIso2
                timezone
                gmt
              }
              scheduledGate
              scheduledDateTime
              scheduledTerminal
              actualGate
              actualDateTime
              estimatedDateTime
              actualTerminal
            }
            destination {
              id
              airport {
                airportName
                iataCode
                icaoCode
                countryName
                countryIso2
                timezone
                gmt
              }
              scheduledGate
              scheduledDateTime
              scheduledTerminal
              actualGate
              estimatedDateTime
              actualDateTime
              actualTerminal
            }
          }
          airport {
            icaoCode
            iataCode
            airportName
            countryName
            countryIso2
            timezone
            gmt
          }
          airline {
            icaoCode
            iataCode
            airlineName
            callsign
            fleetSize
            countryName
            countryIso2
            dateFounded
          }
        }
        routes {
          id
          groupSize
          operatorNote
          operatorConfirmation
          driverNote
          farmAffiliateVariant
          pricing {
            id
            baseRateAmt
            baseRateVariant
            hourlyBaseRate
            hourlyBaseRateHours
            driverGratuityAmt
            driverGratuityPercent
            promoDiscountAmt
            promoDiscountPercent
            taxAmt
            taxPercent
            tollsAmt
            meetGreetAmt
            otherName
            otherAmt
            otherPercent
            other2Name
            other2Amt
            other2Percent
            other3Name
            other3Amt
            other3Percent
            promoCodeId
            promoCodeName
            promoCodeAmt
            promoCodePercent
            forwardFacingSeatQuantity
            forwardFacingSeatAmt
            rearFacingSeatQuantity
            rearFacingSeatAmt
            boosterSeatQuantity
            boosterSeatAmt
            totalAmt
          }
          driverPayoutFlatRate
          driverPayoutHours
          driverPayoutRatePerHour
          driverPayoutGratuity
          payments {
            id
          }
          vehicle {
            id
            capacity
            description
            name
            available
            licensePlate
            exteriorColor
            vinNumber
            weekendHourlyCost
            weekdayHourlyCost
            weekendMinMinutes
            weekdayMinMinutes
            minimumTotalBaseRate
            deadheadRatePerMile
            tripRatePerMile
            totalDeadheadDurationMinutes
            enableBaseRateAutomation
            features {
              id
              name
              category
            }
            photos {
              id
              url
              photoIndex
            }
            vehicleType {
              typeName
              typeSlug
            }
            settings {
              id
              forwardFacingSeat {
                id
                type
                active
                quantity
                amt
                imageUrl
                description
              }
              rearFacingSeat {
                id
                type
                active
                quantity
                amt
                imageUrl
                description
              }
              boosterSeat {
                id
                type
                active
                quantity
                amt
                imageUrl
                description
              }
            }
          }
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          carryOnLuggage
          checkedLuggage
          oversizeLuggage
        }
      }
    }
  }
`;

export const LOAD_TRIP_SUMMARY_QUERY = gql`
  query TripSummary($id: ID!) {
    node(id: $id) {
      id
      ... on Trip ${TRIP_SUMMARY_FIELDS}
    }
  }
`;

export const LOAD_TRIPS_FOR_BOOKING_CONTACT_QUERY = gql`
  query LoadTripsForBookingContact(
    $contactId: ID!
    $cursor: String
    $limit: Int
  ) {
    loadTripsForBookingContact(
      contactId: $contactId
      after: $cursor
      first: $limit
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ... on Trip {
            id
            tripNumber
            cancelledAt
            contact {
              id
              firstName
              lastName
              mobilePhone
            }
            tempPassenger {
              name
              mobilePhone
            }
            stops {
              id
              dateTime
            }
            farmedRouteStatus
            routes {
              id
              farmRelationship
              farmeePricing {
                totalAmt
              }
            }
            totalAmount
            paymentStatus
            request {
              id
              stage
              orderNumber
            }
          }
        }
      }
    }
  }
`;

export const LOAD_AFFILIATE_TRIPS_QUERY = gql`
  query LoadAffiliateTrips(
    $farmAffiliateId: ID
    $externalOperatorId: ID
    $cursor: String
    $limit: Int
  ) {
    loadAffiliateTrips(
      farmAffiliateId: $farmAffiliateId
      externalOperatorId: $externalOperatorId
      after: $cursor
      first: $limit
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ... on Trip {
            id
            tripNumber
            cancelledAt
            contact {
              id
              firstName
              lastName
              mobilePhone
            }
            tempPassenger {
              name
              mobilePhone
            }
            stops {
              id
              dateTime
            }
            farmedRouteStatus
            routes {
              id
              farmRelationship
              farmeePricing {
                totalAmt
                paymentStatus
              }
            }
            totalAmount
            paymentStatus
            request {
              id
              stage
              orderNumber
            }
          }
        }
      }
    }
  }
`;

export const LOAD_TRIPS_FOR_COMPANY_QUERY = gql`
  query LoadCompanyTrips($companyId: ID!, $cursor: String, $limit: Int) {
    loadCompanyTrips(companyId: $companyId, after: $cursor, first: $limit) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ... on Trip {
            id
            tripNumber
            cancelledAt
            contact {
              id
              firstName
              lastName
              mobilePhone
            }
            tempPassenger {
              name
              mobilePhone
            }
            stops {
              id
              dateTime
            }
            farmedRouteStatus
            routes {
              id
              farmRelationship
              farmeePricing {
                totalAmt
              }
            }
            totalAmount
            paymentStatus
            request {
              id
              stage
              orderNumber
              bookingContact {
                id
                firstName
                lastName
                email
                mobilePhone
                linkedPassengers {
                  id
                  firstName
                  lastName
                  createdAt
                  updatedAt
                  email
                  mobilePhone
                  mobilePhoneInternational
                  preferences
                  homeAddress
                  workAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_TRIP_MUTATION = gql`
  mutation UpdateTrip($input: UpdateTripInput!) {
    updateTrip(input: $input) {
      trip {
        id
        createdAt
        updatedAt
        requestId
        request {
          id
          company {
            id
          }
        }
        shuttleRoute {
          id
          name
          company {
            id
            name
          }
        }
        totalGroupSize
        totalDuration
        estimatedDuration
        roundTripVariant
        tripCategory
        returnTrip {
          id
        }
        useTotalDuration
        amountDue
        paymentStatus
        totalAmount
        note
        tripNumber
        comments {
          id
          bodyText
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
          }
        }
        billings {
          id
          createdAt
          updatedAt
          amount
          note
          action
        }
        contact {
          id
          firstName
          lastName
          mobilePhone
          mobilePhoneInternational
          email
          preferences
          homeAddress
          workAddress
        }
        stops {
          id
          createdAt
          updatedAt
          stopIndex
          variant
          location
          locationAlias
          dateTime
          groupSize
          flightNumber
          airport {
            icaoCode
            iataCode
            airportName
            countryName
            countryIso2
            timezone
            gmt
          }
          airline {
            icaoCode
            iataCode
            airlineName
            callsign
            fleetSize
            countryName
            countryIso2
            dateFounded
          }
        }
        routes {
          id
          createdAt
          updatedAt
          driverNote
          isFarmedRouteInvoiced
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          vehicle {
            name
            capacity
            description
          }
        }
      }
    }
  }
`;

export const CREATE_TRIP_MUTATION = gql`
  mutation CreateTrip($input: CreateTripInput!) {
    createTrip(input: $input) {
      trip {
        id
        totalDuration
        estimatedDuration
        useTotalDuration
        totalGroupSize
        createdAt
        updatedAt
        cancelledAt
        requestId
        tempPassenger {
          name
        }
        shuttleRoute {
          id
          name
          company {
            id
            name
          }
        }
        tripNumber
        paymentStatus
        amountDue
        totalAmount
        closedAt
        temporaryPassenger
        googleDirectionsResult
        note
        farmedRouteStatus
        otherTripsFarmedRouteStatus
        returnTrip {
          id
          totalDuration
          estimatedDuration
          useTotalDuration
          totalGroupSize
          createdAt
          updatedAt
          cancelledAt
          requestId
          tripNumber
          paymentStatus
          amountDue
          totalAmount
          closedAt
          temporaryPassenger
          googleDirectionsResult
          note
          farmedRouteStatus
          otherTripsFarmedRouteStatus
          routes {
            id
            groupSize
            operatorConfirmation
            dispatchStatus
            isFarmedRoute
            farmRelationship
            canEditFarmeePricing
            pricing {
              id
              baseRateAmt
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
              totalAmt
              amountPaid
              paymentStatus
            }
            farmeePricing {
              id
              baseRateAmt
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
              totalAmt
              amountPaid
              paymentStatus
            }
            farmAffiliateVariant
            requestedVehicleNote
            farmAffiliate {
              ... on ExternalOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
              }
              ... on MoovsNetworkOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
                isMoovsLiteOperator
              }
              ... on GriddnetOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
              }
              ... on MoovsAiOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
              }
            }
            farmStatus
            farmoutNote
            vehicle {
              id
              name
            }
            driver {
              id
              firstName
              lastName
              mobilePhone
              email
            }
          }
          stops {
            id
            stopIndex
            variant
            location
            locationAlias
            dateTime
            groupSize
            createdAt
            updatedAt
            flightNumber
            note
            airport {
              icaoCode
              iataCode
              airportName
              countryName
              countryIso2
              timezone
              gmt
              coordinates
            }
            airline {
              icaoCode
              iataCode
              airlineName
              callsign
              fleetSize
              countryName
              countryIso2
              dateFounded
            }
            trackedFlight {
              id
              faFlightId
              updatedAt
              airline {
                icaoCode
                airlineName
                iataCode
              }
              actualAirline {
                airlineName
                iataCode
                icaoCode
              }
              flightStatus
              flightNumber
              actualFlightNumber
              origin {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                actualDateTime
                estimatedDateTime
                actualTerminal
              }
              destination {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                estimatedDateTime
                actualDateTime
                actualTerminal
              }
            }
          }
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
        }
        billings {
          id
          createdAt
          updatedAt
          amount
          note
          action
        }
        comments {
          id
          bodyText
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
          }
        }
        contact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          preferences
          homeAddress
          workAddress
        }
        stops {
          id
          stopIndex
          variant
          location
          locationAlias
          dateTime
          groupSize
          createdAt
          updatedAt
          flightNumber
          note
          airport {
            icaoCode
            iataCode
            airportName
            countryName
            countryIso2
            timezone
            gmt
            coordinates
          }
          airline {
            icaoCode
            iataCode
            airlineName
            callsign
            fleetSize
            countryName
            countryIso2
            dateFounded
          }
          trackedFlight {
            id
            faFlightId
            updatedAt
            airline {
              icaoCode
              airlineName
              iataCode
            }
            actualAirline {
              airlineName
              iataCode
              icaoCode
            }
            flightStatus
            flightNumber
            actualFlightNumber
            origin {
              id
              airport {
                airportName
                iataCode
                icaoCode
                countryName
                countryIso2
                timezone
                gmt
              }
              scheduledGate
              scheduledDateTime
              scheduledTerminal
              actualGate
              actualDateTime
              estimatedDateTime
              actualTerminal
            }
            destination {
              id
              airport {
                airportName
                iataCode
                icaoCode
                countryName
                countryIso2
                timezone
                gmt
              }
              scheduledGate
              scheduledDateTime
              scheduledTerminal
              actualGate
              estimatedDateTime
              actualDateTime
              actualTerminal
            }
          }
        }
        routes {
          id
          groupSize
          operatorConfirmation
          dispatchStatus
          isFarmedRoute
          farmRelationship
          pricing {
            id
            baseRateAmt
            hourlyBaseRate
            hourlyBaseRateHours
            driverGratuityAmt
            driverGratuityPercent
            promoDiscountAmt
            promoDiscountPercent
            taxAmt
            taxPercent
            tollsAmt
            meetGreetAmt
            otherName
            otherAmt
            otherPercent
            other2Name
            other2Amt
            other2Percent
            other3Name
            other3Amt
            other3Percent
            promoCodeId
            promoCodeName
            promoCodeAmt
            promoCodePercent
            forwardFacingSeatQuantity
            forwardFacingSeatAmt
            rearFacingSeatQuantity
            rearFacingSeatAmt
            boosterSeatQuantity
            boosterSeatAmt
            totalAmt
            amountPaid
            paymentStatus
          }
          farmeePricing {
            id
            baseRateAmt
            hourlyBaseRate
            hourlyBaseRateHours
            driverGratuityAmt
            driverGratuityPercent
            promoDiscountAmt
            promoDiscountPercent
            taxAmt
            taxPercent
            tollsAmt
            otherName
            otherAmt
            otherPercent
            other2Name
            other2Amt
            other2Percent
            other3Name
            other3Amt
            other3Percent
            promoCodeId
            promoCodeName
            promoCodeAmt
            promoCodePercent
            forwardFacingSeatQuantity
            forwardFacingSeatAmt
            rearFacingSeatQuantity
            rearFacingSeatAmt
            boosterSeatQuantity
            boosterSeatAmt
            totalAmt
            amountPaid
            paymentStatus
          }
          farmAffiliateVariant
          requestedVehicleNote
          farmAffiliate {
            ... on ExternalOperator {
              id
              operatorName
              operatorEmail
              operatorPhone
            }
            ... on MoovsNetworkOperator {
              id
              operatorName
              operatorEmail
              operatorPhone
              isMoovsLiteOperator
              paymentMethods {
                id
                isPrimary
                payerId
                cardNote
                card {
                  id
                  brand
                  last4
                  expMonth
                  expYear
                }
              }
            }
            ... on GriddnetOperator {
              id
              operatorName
              operatorEmail
              operatorPhone
              paymentMethods {
                id
                isPrimary
                payerId
                cardNote
                card {
                  id
                  brand
                  last4
                  expMonth
                  expYear
                }
              }
            }
            ... on MoovsAiOperator {
              id
              operatorName
              operatorEmail
              operatorPhone
              paymentMethods {
                id
                isPrimary
                payerId
                cardNote
                card {
                  id
                  brand
                  last4
                  expMonth
                  expYear
                }
              }
            }
          }
          farmStatus
          farmoutNote
          publicId
          driverStatus {
            name
          }
          vehicle {
            id
            baseRateAutomation {
              total
              lineItems {
                name
                description
                lineTotal
                source
              }
            }
            enableBaseRateAutomation
            name
            capacity
            description
            photos {
              id
              url
              photoIndex
            }
            vehicleType {
              typeName
              typeSlug
            }
          }
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
        }
      }
    }
  }
`;

export const REMOVE_TRIP_MUTATION = gql`
  mutation RemoveTrip($input: RemoveTripInput!) {
    removeTrip(input: $input) {
      trip {
        id
      }
      outboundTrip {
        id
      }
    }
  }
`;

export const UNDO_REMOVE_TRIP_MUTATION = gql`
  mutation UndoRemoveTrip($input: UndoRemoveTripInput!) {
    undoRemoveTrip(input: $input) {
      trip {
        id
      }
    }
  }
`;

export const CLOSE_OUT_TRIP_MUTATION = gql`
  mutation CloseOutTrip($input: CloseOutTripInput!) {
    closeOutTrip(input: $input) {
      trip {
        id
        closedAt
      }
    }
  }
`;

export const UNDO_CLOSE_OUT_TRIP_MUTATION = gql`
  mutation UndoCloseOutTrip($input: UndoCloseOutTripInput!) {
    undoCloseOutTrip(input: $input) {
      trip {
        id
        closedAt
      }
    }
  }
`;

export const CANCEL_TRIP_MUTATION = gql`
  mutation CancelTrip($input: CancelTripInput!) {
    cancelTrip(input: $input) {
      trip {
        id
        # note: do not add cancelledAt here as updating trip.cancelledAt w/o request.cancelledAt
        # if cancelling only active trip leads to bugs. Instead we just refetch request
      }
    }
  }
`;

export const REOPEN_TRIP_MUTATION = gql`
  mutation ReopenTrip($input: ReopenTripInput!) {
    reopenTrip(input: $input) {
      trip {
        id
      }
    }
  }
`;

export const CREATE_RETURN_TRIP_MUTATION = gql`
  mutation CreateReturnTrip($input: CreateReturnTripInput!) {
    createReturnTrip(input: $input) {
      trip {
        id
        tempPassenger {
          name
        }
        contact {
          id
        }
      }
    }
  }
`;

export const CREATE_DUPLICATE_TRIP_MUTATION = gql`
  mutation CreateDuplicateTrip($input: CreateDuplicateTripInput!) {
    createDuplicateTrip(input: $input) {
      request {
        id
        trips {
          id
        }
      }
    }
  }
`;

export const LOAD_FILTERABLE_DISPATCH_TRIPS = gql`
  query LoadFilterableDispatchTrips(
    $startDate: Date!
    $endDate: Date!
    $companyIds: [ID]
    $driverIds: [ID]
    $vehicleIds: [ID]
    $affiliateIds: [ID]
    $tripStatuses: [StatusSlug]
    $paidStatus: PaidNotPaid
    $openClosedTrip: OpenClosedTrip
    $driverAssigned: AssignedUnassigned
    $cursor: String
    $limit: Int
  ) {
    loadFilterableDispatchTrips(
      startDate: $startDate
      endDate: $endDate
      companyIds: $companyIds
      driverIds: $driverIds
      vehicleIds: $vehicleIds
      affiliateIds: $affiliateIds
      tripStatuses: $tripStatuses
      paidStatus: $paidStatus
      openClosedTrip: $openClosedTrip
      driverAssigned: $driverAssigned
      after: $cursor
      first: $limit
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ... on Trip {
            id
            tripNumber
            estimatedDuration
            useTotalDuration
            totalDuration
            totalGroupSize
            tripCategory
            roundTripVariant
            note
            childSeats
            closedAt
            amountDue
            tempPassenger {
              name
              mobilePhone
            }
            contact {
              firstName
              lastName
              mobilePhone
              email
            }
            request {
              id
              orderNumber
              orderType
              company {
                name
                email
              }
              bookingContact {
                firstName
                lastName
              }
            }
            stops {
              location
              locationAlias
              dateTime
              originalDateTime
              flightOffset
              pickUpVariant
            }
            routes {
              id
              driverNote
              dispatchStatus
              farmRelationship
              isFarmedRoute
              farmeePricing {
                totalAmt
              }
              pricing {
                totalAmt
              }
              alert {
                newDriver
                uniqueLocation
                airport
                largeVehicle
                partner
                luggage
                signage
                carSeat
              }
              farmAffiliate {
                ... on ExternalOperator {
                  id
                  operatorName
                }
                ... on MoovsNetworkOperator {
                  id
                  operatorName
                }
                ... on GriddnetOperator {
                  id
                  operatorName
                }
                ... on MoovsAiOperator {
                  id
                  operatorName
                }
              }
              driverStatus {
                name
              }
              routeDriver {
                routeAvailability {
                  available
                }
                personalAvailability {
                  available
                }
                driver {
                  firstName
                  lastName
                  mobilePhone
                }
              }
              driver {
                firstName
                lastName
              }
              driverStatus {
                assignedAt
                acceptedAt
                declinedAt
              }
              vehicle {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const LOAD_TRIP_CONFLICT_QUERY = gql`
  query LoadTripConflict($vehicleId: ID!, $tripId: ID!) {
    loadTripConflict(vehicleId: $vehicleId, tripId: $tripId)
  }
`;
