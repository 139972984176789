import React from "react";
import first from "lodash/first";
import compact from "lodash/compact";

import { deriveIsFarmee } from "globals/utils/farm";
import { Request, Trip } from "types";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";
import RequestActivityLogDialog from "components/globals/ActivityLog/RequestActivityLogDialog";
import ViewReservationPaymentsDialog from "pages/requests/RequestOverview/ReservationOverview/ViewReservationPaymentsDialog";
import DriverPayoutDialog from "components/driverPayout/update/DriverPayoutDialog";
import CopyLinksDialog from "pages/requests/RequestOverview/ReservationOverview/CopyLinksDialog";
import CreateFarmedRouteDialog from "pages/requests/RequestOverview/ReservationOverview/farm/CreateFarmedRouteDialog"; // TODO: Move to this directory
import CancelFarmOutDialog from "./components/CancelFarmOutDialog";
import FarmeePricingDialog from "./components/FarmeePricingDialog";
import TripPricingDialog from "./components/TripPricingDialog";
import CancelTripDialog from "./components/CancelTripDialog";
import DeleteTripDialog from "./components/DeleteTripDialog";
import DispatchLogDialog from "components/dispatch/DispatchLogDialog";
import DuplicateReservationDialog from "./components/DuplicateReservationDialog";
import DuplicateTripDialog from "./components/DuplicateTripDialog";
import ViewPDFsDialog from "./components/ViewPDFsDialog";
import {
  useDuplicateTripClick,
  useViewActivityLogClick,
  useCancelFarmOutClick,
  useFarmOutTripClick,
  useCopyTripDetailsClick,
  useViewDispatchLogClick,
  useViewPaymentsClick,
  useCancelTripClick,
  useUpdatePricingClick,
  useLinksClick,
  useAffiliatePricingClick,
  useUpdateDriverPayoutClick,
  useDeleteTripClick,
  useAddReturnTripClick,
  useDuplicateReservationClick,
  useViewPDFsClick,
  useOverrideTripSMSRecipientsClick,
} from "./hooks";
import OverrideSMSRecipientsForTripDialog from "./components/OverideSMSRecipientsForTripDialog";

type ViewMoreActionButtonProps = {
  request: Request;
  trip: Trip;
};

function ViewMoreActionButton(props: ViewMoreActionButtonProps) {
  const { request, trip } = props;

  // derived state
  const route = first(trip.routes);
  const isFarmee = deriveIsFarmee(route);
  const currentTripIndex = request.trips.findIndex(({ id }) => id === trip.id);

  // hooks
  // reservation
  const updatePricing = useUpdatePricingClick({
    request,
    trip,
    analyticsName: "reservation_pricingOpened",
  });
  const viewCopyLinksDialog = useLinksClick();
  const viewActivityLog = useViewActivityLogClick({
    isFarmee,
    analyticsName: "reservation_activityLogViewed",
  });
  const viewPaymentsDialog = useViewPaymentsClick({
    analyticsName: "reservation_paymentsViewed",
    requestStage: request.stage,
  });
  const updateDriverPayout = useUpdateDriverPayoutClick({
    farmRelationship: route.farmRelationship,
    farmStatus: route.farmStatus,
    analyticsName: "reservation_driverPayoutOpened",
    requestStage: request.stage,
  });
  const duplicateReservation = useDuplicateReservationClick({
    request,
    isFarmee,
  });

  // trip
  const duplicateTrip = useDuplicateTripClick({
    trip,
    isFarmee,
    analyticsName: "reservation_tripDuplicated",
  });
  const cancelTrip = useCancelTripClick({
    isFarmee,
    analyticsName: "reservation_cancelTripDialogOpened",
  });
  const deleteTrip = useDeleteTripClick({
    trip,
    analyticsName: "reservation_deleteTripDialogOpened",
  });
  const addReturnTrip = useAddReturnTripClick({
    request,
    trip,
    isFarmee,
  });
  const copyTripDetails = useCopyTripDetailsClick({
    request,
    trip,
    isFarmee,
    analyticsName: "trip_tripDetailscopied",
  });

  // dispatch
  const viewDispatchLog = useViewDispatchLogClick({
    analyticsName: "reservation_dispatchLogViewed",
    requestStage: request.stage,
  });

  // farming
  const farmOutTrip = useFarmOutTripClick({
    isFarmee,
    farmStatus: route.farmStatus,
    analyticsName: "reservation_farmoutDialogOpened",
    requestStage: request.stage,
  });
  const affiliatePricing = useAffiliatePricingClick({
    isFarmee,
    trip,
    analyticsName: "reservation_affiliatePricingOpened",
  });
  const cancelFarmOut = useCancelFarmOutClick({
    isFarmee,
    farmStatus: route.farmStatus,
    isFarmedRouteInvoiced: route.isFarmedRouteInvoiced,
    analyticsName: "farmedRoute_cancelDialogOpened",
  });

  const overrideTripNotifySettings = useOverrideTripSMSRecipientsClick({
    analyticsName: "overrideNotifications_opened",
  });

  // pdf
  const viewPDFs = useViewPDFsClick({ requestStage: request.stage });

  const options = [
    "RESERVATION",
    updatePricing,
    viewCopyLinksDialog,
    viewActivityLog,
    viewPaymentsDialog,
    updateDriverPayout,
    duplicateReservation,
    "divider",

    "TRIP",
    duplicateTrip,
    addReturnTrip,
    copyTripDetails,
    overrideTripNotifySettings,
    cancelTrip,
    deleteTrip,
    "divider",

    "DISPATCH",
    viewDispatchLog,

    !isFarmee && "divider",
    !isFarmee && "FARMING",

    farmOutTrip,
    affiliatePricing,
    cancelFarmOut,
    "divider",

    "PDF",
    viewPDFs,
  ];

  return (
    <>
      <MoovsEllipsisMenu variant="actionButton" options={compact(options)} />
      <RequestActivityLogDialog
        open={viewActivityLog.open}
        onClose={viewActivityLog.onClose}
        requestId={request.id}
        requestStage={request.stage}
        orderNumber={request.orderNumber}
      />
      <ViewReservationPaymentsDialog
        open={viewPaymentsDialog.open}
        onClose={viewPaymentsDialog.onClose}
        requestId={request.id}
      />
      <CopyLinksDialog
        open={viewCopyLinksDialog.open}
        onClose={viewCopyLinksDialog.onClose}
        requestId={request.id}
        trip={trip}
      />
      {overrideTripNotifySettings.open && (
        <OverrideSMSRecipientsForTripDialog
          open={overrideTripNotifySettings.open}
          onClose={overrideTripNotifySettings.onClose}
          trip={trip}
        />
      )}
      <DuplicateReservationDialog
        open={duplicateReservation.open}
        onClose={duplicateReservation.onClose}
        request={request}
      />
      {/* Update Pricing */}
      {isFarmee ? (
        <FarmeePricingDialog
          open={updatePricing.open}
          onClose={updatePricing.onClose}
          currentTripIndex={currentTripIndex}
          pricings={updatePricing.pricings}
          routeId={route.id}
        />
      ) : (
        <TripPricingDialog
          open={updatePricing.open}
          onClose={updatePricing.onClose}
          currentTripIndex={currentTripIndex}
          pricings={updatePricing.pricings}
          request={request}
          legacyBillings={trip.billings}
        />
      )}
      {/* Cancel Trip */}
      {cancelTrip.open && (
        <CancelTripDialog
          open
          onClose={cancelTrip.onClose}
          trip={trip}
          request={request}
          analyticsName="reservation_cancelTrip"
        />
      )}
      {/* Delete Trip */}
      <DeleteTripDialog
        open={deleteTrip.open}
        onClose={deleteTrip.onClose}
        trip={trip}
        request={request}
        analyticsName="reservation_deleteTrip"
      />
      {/* Duplicate Trip*/}
      <DuplicateTripDialog
        open={duplicateTrip.open}
        onClose={duplicateTrip.onClose}
        request={request}
        tripId={trip.id}
      />
      {/* Farm-Out Trip */}
      {farmOutTrip.open && ( // TODO: why did we use this pattern instead of normal open prop?
        <CreateFarmedRouteDialog
          open
          onClose={farmOutTrip.onClose}
          tripId={trip.id}
          routeId={route.id}
        />
      )}
      {/* Affiliate Pricing */}
      <FarmeePricingDialog
        open={affiliatePricing.open}
        onClose={affiliatePricing.onClose}
        currentTripIndex={currentTripIndex}
        pricings={affiliatePricing.pricings}
        routeId={route.id}
        hidePromoCodes={true}
      />
      {/* Cancel Farm-Out Trip */}
      <CancelFarmOutDialog
        open={cancelFarmOut.open}
        onClose={cancelFarmOut.onClose}
        routeId={route.id}
        requestId={request.id}
      />
      {/* Dispatch Log */}
      <DispatchLogDialog
        open={viewDispatchLog.open}
        onClose={viewDispatchLog.onClose}
        routeId={route.id}
      />
      {/* Update Driver Payout */}
      <DriverPayoutDialog
        open={updateDriverPayout.open}
        onClose={updateDriverPayout.onClose}
        orderNumber={request.orderNumber}
        currentTripIndex={currentTripIndex}
        trips={request.trips}
        requestStage={request.stage}
      />
      {/* View PDFs */}
      <ViewPDFsDialog
        open={viewPDFs.open}
        onClose={viewPDFs.onClose}
        requestId={request.id}
        trip={trip}
      />
    </>
  );
}

export default ViewMoreActionButton;
